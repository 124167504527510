import { createAction, props } from "@ngrx/store";
import { PaginationMetaData } from "@core/interfaces/pagination.interface";
import { Cities, CreateCity } from "../../interface/cities.interface";

export enum CitiesActionsTypes {
  getCities = "[Cities] get Cities",
  successGetCities = "[Cities] Success get Cities",
  errorGetCities = "[Cities] Error get Cities",

  createCities = "[Cities] create Cities",
  successCreateCities = "[Cities] Success create Cities",
  errorCreateCities = "[Cities] Error create Cities",
}

export const getCities = createAction(
  CitiesActionsTypes.getCities,
  props<{ filters: string }>()
);
export const successGetCities = createAction(
  CitiesActionsTypes.successGetCities,
  props<{ cities: Cities[]; pagination: PaginationMetaData }>()
);
export const errorGetCities = createAction(
  CitiesActionsTypes.errorGetCities,
  props<{ payload: any }>()
);

export const createCities = createAction(
  CitiesActionsTypes.createCities,
  props<{ city: CreateCity }>()
);
export const successCreateCities = createAction(
  CitiesActionsTypes.successCreateCities,
  props<{ city: Cities }>()
);
export const errorCreateCities = createAction(
  CitiesActionsTypes.errorCreateCities,
  props<{ payload: any }>()
);

import { createAction, props } from "@ngrx/store";
import { PaginationMetaData } from "@core/interfaces/pagination.interface";
import {
  Countries,
  CreateCountry,
  CreateCountryResponse,
} from "../../interface/countries.interface";

export enum CountriesActionsTypes {
  getCountries = "[Countries] get Countries",
  successGetCountries = "[Countries] Success get Countries",
  errorGetCountries = "[Countries] Error get Countries",

  createCountries = "[Countries] create Countries",
  successCreateCountries = "[Countries] Success create Countries",
  errorCreateCountries = "[Countries] Error create Countries",
}

export const getCountries = createAction(
  CountriesActionsTypes.getCountries,
  props<{ filters: string }>()
);
export const successGetCountries = createAction(
  CountriesActionsTypes.successGetCountries,
  props<{ countries: Countries[]; pagination: PaginationMetaData }>()
);
export const errorGetCountries = createAction(
  CountriesActionsTypes.errorGetCountries,
  props<{ payload: any }>()
);

export const createCountries = createAction(
  CountriesActionsTypes.createCountries,
  props<{ name: string; code: string }>()
);
export const successCreateCountries = createAction(
  CountriesActionsTypes.successCreateCountries,
  props<{ country: Countries }>()
);
export const errorCreateCountries = createAction(
  CountriesActionsTypes.errorCreateCountries,
  props<{ payload: any }>()
);
